<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="main_id" prop="mainId">
      <el-input v-model="dataForm.mainId" placeholder="main_id"></el-input>
    </el-form-item>
    <el-form-item label="文件名" prop="fileName">
      <el-input v-model="dataForm.fileName" placeholder="文件名"></el-input>
    </el-form-item>
    <el-form-item label="01:-一般文件如word,02:录音文件" prop="fileType">
      <el-input v-model="dataForm.fileType" placeholder="01:-一般文件如word,02:录音文件"></el-input>
    </el-form-item>
    <el-form-item label="上传时间" prop="upDate">
      <el-input v-model="dataForm.upDate" placeholder="上传时间"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olfmajorfile.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        dataForm: {
          id: null,
          mainId: '',
          fileName: '',
          fileType: '',
          upDate: ''
        },
        dataRule: {
          mainId: [
            { required: true, message: 'main_id不能为空', trigger: 'blur' }
          ],
          fileName: [
            { required: true, message: '文件名不能为空', trigger: 'blur' }
          ],
          fileType: [
            { required: true, message: '01:-一般文件如word,02:录音文件不能为空', trigger: 'blur' }
          ],
          upDate: [
            { required: true, message: '上传时间不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.mainId = data.data.mainId
                this.dataForm.fileName = data.data.fileName
                this.dataForm.fileType = data.data.fileType
                this.dataForm.upDate = data.data.upDate
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
			  'mainId': this.dataForm.mainId,
			  'fileName': this.dataForm.fileName,
			  'fileType': this.dataForm.fileType,
			  'upDate': this.dataForm.upDate
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
